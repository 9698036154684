import { withFragmentArray } from '@liquorice/gql-utils';
import { PROFILE_CARD_FRAGMENT } from '../EntryCards/ProfileCard';
import Box from '../ui/Box';
import Txt, { TxtProps } from '../ui/Txt';

type Authors = TxtProps<
  'span',
  {
    join?: string;
  }
>;

const Authors = withFragmentArray(PROFILE_CARD_FRAGMENT, (data, { join, ...props }: Authors) => {
  return (
    <Box>
      {data.map((item, index) => {
        return (
          <Txt key={item.id} {...props}>
            {item?.entryTitle}
            {!(index === data.length - 1) && data.length > 1 ? `${join ?? ','} ` : ''}
          </Txt>
        );
      })}
    </Box>
  );
});

export default Authors;
