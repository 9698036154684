import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Fpalette.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA63VW2vCMBgG4Ht%2FRaA3ClVyaJI2u5rO%2FI3Rg3WbzmyZuhP%2B95FuOj%2BVDwISyMXblyftRyHGO7cm3z1ChsN7vdh4Lqghfl6VfS5ESnimwyZSQkdaDm6Oi2xfzFToyJRwyS4V%2Bb4oeSiyv%2B28KA5FsS8KdamYHd6xCMVwvpSH4q5nzj9LGpJQSgGjDEnsbVgg1oYk0zwsEOeGJG3bgqwIqpZjqUBcBqGw2goQV%2BE8ZaUdg7gO8cQyy0DchPjOsukExLNgW6usBXFrSDLjtPvEXW%2F0ly7gEOaGbEvf%2Fx8KHOvDyeN8cGwtcUvjlgTWM26pGGuFW3mM5XCriJnXC26VMe%2F1iltVjOVxq46x3nCribHWuDWLsTa41cZY2yv%2BX%2B%2FQejwpU2g9odYHbrEY6xO3eIz1hVsixmIUx7IojF1h%2BpVrfodVu6Xzp5W0u2Q6pyrrxdy7zaoZXqrmaXebBPMHOJy6D4UHAAA%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Ftypography.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42UW26DMBBF%2F7uK%2BUzUYPmBbXDX0DVUhJiElkcKNOlD2XsFIaWGsZTf69GZ8dxrm6auO%2Fh5AAiCF5ZxUZ0lNfCcVE19tBtok6oNWtvk2ZNTw%2B6o4XfUCANpkZTHlSCyseUG0qRIV5xozVikewkegREh1em83kDY2HLtEsKJwF0EFWIiUKYHgiBqKJtR5I3CybXtiGEkVuGNoWI5MhCCmgjKHYTqCBtkidATwgEIpiPGRwIlQsQDgeNXiQzwxpauGBtgZBjC1RPP%2FmnI%2F2bmRDN%2BXT928S3WL%2FX02%2FX6Ura4nPUyX%2Bp7A3T0yT049AeInqObZb6YId68TgTuIKJISjWZo3Q0moOs6u0GYfOUCY5lZOw1oxQTZQbxjLJElBNCudcJQ45lbWw1w1S9PwLZd90fMMS5o8cIPG%2FoQ3v3hKLx9WwNsIXY%2BSL04YnQyUBIqaudDai59mlA9trlYVvvvoavNaurLmjzb%2BtOWOSVDQ423x%2B6f6%2Fl8gulCgQFkwUAAA%3D%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Fgrid.css.ts.vanilla.css%22%2C%22source%22%3A%22OnJvb3QgewogIC0tZTVmcmxpMDogMTI7CiAgLS1lNWZybGkxOiAxNnB4OwogIC0tZTVmcmxpMjogMjRweDsKICAtLWU1ZnJsaTM6IDI0cHg7Cn0%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Fspacing.css.ts.vanilla.css%22%2C%22source%22%3A%22OnJvb3QgewogIC0tcDNvNWdtMDogMDsKICAtLXAzbzVnbTE6IDFweDsKICAtLXAzbzVnbTI6IDJweDsKICAtLXAzbzVnbTM6IHZhcigtLWU1ZnJsaTMpOwogIC0tcDNvNWdtNDogdmFyKC0tZTVmcmxpMSk7CiAgLS1wM281Z201OiA0MHB4OwogIC0tcDNvNWdtNjogMC4xMjVyZW07CiAgLS1wM281Z203OiAwLjI1cmVtOwogIC0tcDNvNWdtODogMC4zNzVyZW07CiAgLS1wM281Z205OiAwLjVyZW07CiAgLS1wM281Z21hOiAwLjc1cmVtOwogIC0tcDNvNWdtYjogMXJlbTsKICAtLXAzbzVnbWM6IDEuMjVyZW07CiAgLS1wM281Z21kOiAxLjVyZW07CiAgLS1wM281Z21lOiAycmVtOwogIC0tcDNvNWdtZjogMi4zNzVyZW07CiAgLS1wM281Z21nOiBjbGFtcCgyLjM3NXJlbSwgY2FsYygxLjQ2NHJlbSArIDEuNjk1dncpLCAzcmVtKTsKICAtLXAzbzVnbWg6IGNsYW1wKDNyZW0sIGNhbGMoMi4yNzExODc1cmVtICsgMS4zNTZ2dyksIDMuNXJlbSk7CiAgLS1wM281Z21pOiBjbGFtcCgzLjVyZW0sIGNhbGMoMi4wNDIzNzVyZW0gKyAyLjcxMnZ3KSwgNC41cmVtKTsKICAtLXAzbzVnbWo6IGNsYW1wKDMuNXJlbSwgY2FsYygxLjMxMzU2MjVyZW0gKyA0LjA2OHZ3KSwgNXJlbSk7CiAgLS1wM281Z21rOiBjbGFtcCg1cmVtLCBjYWxjKDEuMzU1OTM3NXJlbSArIDYuNzgwdncpLCA3LjVyZW0pOwp9%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fvars.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42U3XKCMBCF7%2FsUe6kz4mQDQdy%2BQN%2Bgl53wo1KVYEDFdvrunTBoSdJqZ2BYvpw9u4QkpJVq4fMJIAg6JYS8MIIAGXseISRwCCdALsYkJEBhaSIC610QiEVcd2MWEyQxs9nCVOORDRMCjISjXBLgkjtQEnCbpASN2pX5mGVOczkBmwtd7MewMDBZuHhFgHPkLl4TcM9iQ3CSehIEb7jiYXUWzXQ8XBKgk%2FFuinrmW1Mz9FrZ%2Bfl7t%2BLBqlj5GcpU9Kxr12dn%2BRzM1%2FqToE2f%2FpQ1BKzu%2BjuuO9DrVE7YDIZrjqHl3f6okT2WH0fyf7if6NYHv%2Ba5KVxMZzfT6A8RCsv33P%2B4Zow66mUWu%2FiyD4NCCyEzLLIZ9ovUZpwgldl2rdWxyoNM7ZSewfBIlc4LfYV9J1DIpgjKKlDH1vIJf%2FO5nxIRqFpmZXt5IBQErZZVs1J6%2F0AaE2yKcr1pZ7CX3cs1LKtreC7zdtMPvg5RWQ3RfeeFs%2BMxIXAOGVwSJA6S1C8qi6UEYX%2FqfH0DBwX06T8FAAA%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fui%2FTxt%2FTxt.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA82X346iMBTG7%2BcpzuVOAkaof%2FFhNgUq7VhaphR1drPvvhGH1VazR0Mw3Kn9td9nczjnY%2FIzKo2JqmYGv98AMi21SWBPzY8w7JamAQjFmRH2ffMGsNXKhgcmCm59Mr4m%2F7xNut%2Fn7eFXJ%2F7buNw1JiZzF18g%2BMLFlwi%2BdPEVgq9cfI3gaxenCE5dPEXw1MUzBM9cPEfw3MUZgjMX3yL41sWLFi%2BpKYQKU22tLhOYThasdDB%2BF3MQ4QnHlzrcxkQd5gdX%2BQPjjy6%2Fa%2Fm7dX7m95cHYUtLIb98YHoBavGL%2BcuiXZZCsZDfFSCuITm0oQ%2FM0Mw1VA5taIcZ8lqGGtqQxAx5TUkPbajEDHltrxrakMIMeY3182Lovl70f70G0%2FPalempZ5%2FUq3vq1Zie171tTz2D6XnDpemp94npebNvP3QBV5ghb3YfehtCbkhjhrzsQYBep7jvKHY6w7KjDXOWaUOt0CqBRuXMnE72DuDnpySl2a4wulF56EbCS14D6DxF1fH0NdUmZyYBpW9PtaUMINX51yM142ytrdGqwG764G9Ln97RyAD0edBWNM%2BFKkLJtqe%2FN1ll3IOleCzMEODR2MIEAR6PLU8Q4GRskYIAn40tVRDg87EFCwJ8MbZsQSA%2B9p2%2Fz%2BYLAr0ln40YBOryxSmDQJm%2FOGgQkMWLswaB4%2BDvXM%2FGDeIN0LFkDh4FwOMAOAmAzwLg86DrCA%2FMR0tTyQKwPAB7LqwuR0TVEWotRQ6ppNnuEjFOkUTSqmYJdJ82t2LRrRR%2F7PLasESlKFQCpwiwwQPRut14b%2BWmMdk86Jx854xuR0X0vCjbSvwLwnxrZZcTAAA%3D%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var root = {fn:_7a468({defaultClassName:'_1mrr1pu4',variantClassNames:{color:{black:'_1mrr1pu5',greyLight:'_1mrr1pu6',greyMid:'_1mrr1pu7',white:'_1mrr1pu8',gum:'_1mrr1pu9',gumLight:'_1mrr1pua',pinkLight:'_1mrr1pub',redLight:'_1mrr1puc',orangeLight:'_1mrr1pud',blueLight:'_1mrr1pue',error:'_1mrr1puf'},autoMargin:{true:'_1mrr1pug'},noMargin:{true:'_1mrr1puh'},bold:{true:'_1mrr1pui'},heading:{true:'_1mrr1puj'},variant:{h1:'_1mrr1puk',h2:'_1mrr1pul',h3:'_1mrr1pum',h4:'_1mrr1pun',h5:'_1mrr1puo',h6:'_1mrr1pup','2xs':'_1mrr1puq',xs:'_1mrr1pur',sm:'_1mrr1pus',md:'_1mrr1put',lg:'_1mrr1puu',xl:'_1mrr1puv',body:'_1mrr1puw',inherit:'_1mrr1pux'}},defaultVariants:{},compoundVariants:[]}),variantKeys:['color','autoMargin','noMargin','bold','heading','variant']};
export var txtVars = {color:'var(--_1mrr1pu0)',display:'var(--_1mrr1pu1)',fontWeight:'var(--_1mrr1pu2)'};
export var typographyClasses = {h1:{fontWeight:'var(--_1f23nw5v)',fontFamily:'var(--_1f23nw50)',fontSize:'var(--_1f23nw5i)',lineHeight:'var(--_1f23nw53)'},h2:{fontWeight:'var(--_1f23nw5v)',fontFamily:'var(--_1f23nw50)',fontSize:'var(--_1f23nw5j)',lineHeight:'var(--_1f23nw54)'},h3:{fontWeight:'var(--_1f23nw5v)',fontFamily:'var(--_1f23nw50)',fontSize:'var(--_1f23nw5k)',lineHeight:'var(--_1f23nw55)'},h4:{fontWeight:'var(--_1f23nw5v)',fontFamily:'var(--_1f23nw50)',fontSize:'var(--_1f23nw5l)',lineHeight:'var(--_1f23nw56)'},h5:{fontWeight:'var(--_1f23nw5v)',fontFamily:'var(--_1f23nw50)',fontSize:'var(--_1f23nw5m)',lineHeight:'var(--_1f23nw57)'},h6:{fontWeight:'var(--_1f23nw5v)',fontFamily:'var(--_1f23nw50)',fontSize:'var(--_1f23nw5n)',lineHeight:'var(--_1f23nw58)'},'2xs':{fontFamily:'var(--_1f23nw51)',fontSize:'var(--_1f23nw5u)',lineHeight:'var(--_1f23nw5e)'},xs:{fontFamily:'var(--_1f23nw51)',fontSize:'var(--_1f23nw5t)',lineHeight:'var(--_1f23nw5e)'},sm:{fontFamily:'var(--_1f23nw51)',fontSize:'var(--_1f23nw5s)',lineHeight:'var(--_1f23nw5d)'},md:{fontFamily:'var(--_1f23nw51)',fontSize:'var(--_1f23nw5r)',lineHeight:'var(--_1f23nw5c)'},lg:{fontFamily:'var(--_1f23nw51)',fontSize:'var(--_1f23nw5q)',lineHeight:'var(--_1f23nw5b)'},xl:{fontWeight:'var(--_1f23nw5v)',fontFamily:'var(--_1f23nw50)',fontSize:'var(--_1f23nw5p)',lineHeight:'var(--_1f23nw5a)'},body:{fontWeight:'var(--_1f23nw5v)',fontFamily:'var(--_1f23nw51)',fontSize:'var(--_1f23nw5o)',lineHeight:'var(--_1f23nw59)'},inherit:{}};
export var withHtml = '_1mrr1pu3';