'use client';

import { FragmentType } from '__generated__';
import Carousel from '../Carousel';
import QuoteSingle, { type QUOTE_SINGLE_FRAGMENT } from '../QuoteSingle';
import Box, { BoxProps } from '../ui/Box';
import Container, { ContainerProps } from '../ui/Container';

type QuoteMultiple = BoxProps<
  'div',
  {
    items: (FragmentType<typeof QUOTE_SINGLE_FRAGMENT> | null)[];
    ContainerProps?: ContainerProps;
  }
>;

const QuoteMultiple = ({ items, ContainerProps, ...props }: QuoteMultiple) => {
  if (!items || items.length === 0) return null;

  return (
    <Box paper cx={{ pY: '2xl' }} {...props}>
      <Container {...ContainerProps}>
        {items.length > 1 ? (
          <Carousel
            spaceBetween={24}
            NavigationProps={{
              cx: { mT: 'lg' },
            }}
            items={items?.map((item, i) => (
              <QuoteSingle key={i} data={item} />
            ))}
          />
        ) : (
          <QuoteSingle data={items[0]} />
        )}
      </Container>
    </Box>
  );
};

export default QuoteMultiple;
