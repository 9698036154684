import Icon, { IconName, IconProps } from '@/components/Icon';
import Btn, { BtnComponentWithRef, BtnProps } from '@/components/ui/Btn';
import { Polymorphic } from '@/components/ui/Polymorphic';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import React from 'react';
import * as styles from './IconButton.css';

export type IconButtonBaseProps = {
  icon: IconName;
  IconProps?: Omit<IconProps, 'name'>;
};

export type IconButtonProps<C extends React.ElementType = 'button'> = BtnProps<
  C,
  IconButtonBaseProps
>;

const IconButton: BtnComponentWithRef<IconButtonBaseProps> = React.forwardRef(function IconButton<
  C extends React.ElementType
>(
  { icon, IconProps, children, ...props }: Omit<IconButtonProps<C>, 'icon'>,
  ref?: Polymorphic.Ref<C>
) {
  return (
    <Btn ref={ref} square variant="icon" size="small" {...mergePropsClassName(props, styles.root)}>
      <Icon name={icon} {...mergePropsClassName(IconProps, styles.icon)} />
    </Btn>
  );
});

export default IconButton;
