'use client';

import { assignInlineVars } from '@vanilla-extract/dynamic';
import { ProfileEntryFragment } from '__generated__/graphql';
import useResizeObserver from 'use-resize-observer';
import Box from '../ui/Box';
import Flex from '../ui/Flex';
import Grid from '../ui/Grid';
import * as styles from './PageHeader.css';
import Profile from './Profile';
import { usePageHeader } from './usePageHeader';

type PageHeaderProfileProps = ProfileEntryFragment & React.PropsWithChildren;

export default function PageHeaderProfile({ children, ...props }: PageHeaderProfileProps) {
  const { fallbackHeight, setHeight, height } = usePageHeader();

  const { ref, height: _initialHeight = fallbackHeight } = useResizeObserver<HTMLDivElement>({
    onResize: ({ height }) => {
      setHeight(height);
    },
  });

  return (
    <Box
      className={styles.profileWrapper}
      style={assignInlineVars({
        [styles.profileHeight]: `${height}px`,
      })}>
      <Box ref={ref} colorSet="gumLight" paper className={styles.profile}>
        <Flex columnGap="xs" cx={{ p: 'md', direction: 'column' }}>
          {children}
          <Grid>
            <Grid.Col lg={8}>
              <Profile {...props} />
            </Grid.Col>
          </Grid>
        </Flex>
      </Box>
    </Box>
  );
}
