'use client';

import Box from '../ui/Box';
import Flex, { FlexProps } from '../ui/Flex';
import Txt from '../ui/Txt';

export type QuoteOwnProps = FlexProps<
  'div',
  {
    heading?: string | null;
    label?: string | null;
  }
>;

const Quote = ({ heading, label, ...props }: QuoteOwnProps) => {
  if (!heading) return null;

  return (
    <Flex
      alignItems="start"
      columnGap="lg"
      cx={{ direction: { xs: 'column', md: 'row' } }}
      {...props}>
      <Box>
        <Txt variant="h3">{heading}</Txt>
        {label && <Txt variant="lg">{label}</Txt>}
      </Box>
    </Flex>
  );
};

export default Quote;
