'use client';

import BlockHeader from '@/components/BlockHeader';
import { useEntries } from '@/components/Entry/useEntries';
import ImageAsset from '@/components/ImageAsset';
import Btn from '@/components/ui/Btn';
import Flex from '@/components/ui/Flex';
import Grid from '@/components/ui/Grid';
import { useAppContext } from '@/lib/context/hooks';
import { createBlock } from '@/lib/parsers/blocks';
import { parseColorScheme } from '@/lib/parsers/common/parseColorScheme';
import { parseLinkField } from '@/lib/parsers/linkField';
import { useBreakpointMin } from '@/theme';
import { maybeGet, toBoolean } from '@liquorice/utils';
import { gql } from '__generated__';
import { BlockContainer } from '../../BlockContainer';
import * as styles from './CallToActionBlock.css';

const CALL_TO_ACTION_BLOCK = gql(`
  fragment callToActionBlock on blocks_callToAction_BlockType {
    heading
    content: htmlContentSimple
    linkField {
    ...linkField
    }
    imageSingle {
      ...ImageAsset
    }
    disableBackground
  }    
`);

const CallToActionBlock = createBlock(CALL_TO_ACTION_BLOCK, ({ data, meta }) => {
  if (!data) return null;

  const { heading, content, imageSingle, disableBackground } = data;
  const { data: appContext } = useAppContext() ?? {};
  const md = useBreakpointMin('md');

  const paper = toBoolean(disableBackground);
  const linkField = parseLinkField(data.linkField);
  const entry = useEntries(appContext);
  const colorSet = parseColorScheme(maybeGet(entry, 'colorScheme'));

  return (
    <BlockContainer
      {...{ meta, marginY: true, GridColProps: { md: 10, offsetMd: 1, lg: 8, offsetLg: 2 } }}>
      <Grid
        colorSet={colorSet}
        paper={!paper}
        cx={{ rowGap: { xs: 'md', md: 'xl' } }}
        className={styles.root}>
        <Grid.Col md={!!imageSingle.length ? 8 : 12} className={paper ? styles.contentWrapper : ''}>
          <BlockHeader
            heading={heading}
            content={content}
            ContentProps={{ cx: { mB: !!linkField?.length ? 'sm' : 'none' } }}
            maxWidth="none"
            cx={{
              pT: { xs: !!imageSingle.length ? 'none' : 'md', md: 'md' },
              pB: 'md',
              pX: !paper ? '2xs' : 'none',
            }}>
            {!!linkField?.length && (
              <Flex spacing="2xs" direction="column" wrap>
                {linkField?.map((link, i) => (
                  <Btn key={`${link?.title}-${i}`} variant="outlined" {...link} />
                ))}
              </Flex>
            )}
          </BlockHeader>
        </Grid.Col>
        {!!imageSingle.length && (
          <Grid.Col md={4} className={styles.imageWrapper}>
            <ImageAsset data={imageSingle} layout={md ? 'fillParent' : 'responsive'} />
          </Grid.Col>
        )}
      </Grid>
    </BlockContainer>
  );
});

export default CallToActionBlock;
