import { getFragmentData } from '__generated__';
import { EntriesFragment } from '__generated__/graphql';
import {
  ARTICLE_ENTRY_FRAGMENT,
  INSIGHT_ENTRY_FRAGMENT,
  PAGE_OVERVIEW_ENTRY_FRAGMENT,
  PAGE_STANDARD_ENTRY_FRAGMENT,
  PROFILE_ENTRY_FRAGMENT,
  SERVICE_ENTRY_FRAGMENT,
} from '../Entries/entries.gql';

type Entries = EntriesFragment | null;

export const useEntries = (data?: Entries) => {
  if (!data) return null;

  switch (data?.__typename) {
    case 'article_Entry':
      return getFragmentData(ARTICLE_ENTRY_FRAGMENT, data);
    case 'insight_Entry':
      return getFragmentData(INSIGHT_ENTRY_FRAGMENT, data);
    case 'service_Entry':
      return getFragmentData(SERVICE_ENTRY_FRAGMENT, data);
    case 'profile_Entry':
      return getFragmentData(PROFILE_ENTRY_FRAGMENT, data);
    case 'overview_Entry':
      return getFragmentData(PAGE_OVERVIEW_ENTRY_FRAGMENT, data);
    case 'standard_Entry':
      return getFragmentData(PAGE_STANDARD_ENTRY_FRAGMENT, data);
  }
};
