'use client';

import BasicCard from '@/components/BasicCard';
import ArticleIndexCard from '@/components/EntryCards/ArticleIndexCard';
import OverviewCard from '@/components/EntryCards/OverviewCard';
import ProfileIndexCard from '@/components/EntryCards/ProfileIndexCard';
import ServiceCard from '@/components/EntryCards/ServiceCard';
import StandardCard from '@/components/EntryCards/StandardCard';
import { firstNonNullable } from '@liquorice/allsorts-craftcms-nextjs';
import { withFragment } from '@liquorice/gql-utils';
import { gql } from '__generated__';

const PAGE_CARD_BLOCK = gql(`
  fragment pageCardBlock on blocks_pageCard_BlockType {
      pageSingle {
        ...overviewCard
        ...standardCard
        ...articleIndexCard
        ...profileIndexCard
        ...serviceCard
      }
      imageSingle {
        ...ImageAsset
      }
      heading
      content: htmlContentSimple
  }    
`);

const PageCardBlock = withFragment(PAGE_CARD_BLOCK, (data) => {
  if (!data) return null;

  const { pageSingle, imageSingle: image, heading, content } = data;
  const page = firstNonNullable(pageSingle);

  if (!page && (heading || content || image))
    return <BasicCard item={{ title: heading, description: content, image }} />;

  if (page?.__typename === 'overview_Entry')
    return <OverviewCard data={page} {...{ heading, content, image }} />;

  if (page?.__typename === 'standard_Entry')
    return <StandardCard data={page} {...{ heading, content, image }} />;

  if (page?.__typename === 'articleIndex_Entry') {
    return <ArticleIndexCard data={page} {...{ heading, content, image }} />;
  }

  if (page?.__typename === 'profileIndex_Entry') {
    return <ProfileIndexCard data={page} {...{ heading, content, image }} />;
  }

  if (page?.__typename === 'service_Entry') {
    return <ServiceCard data={page} {...{ heading, content, image }} />;
  }
});

export default PageCardBlock;
