import { withFragment } from '@liquorice/gql-utils';
import { gql } from '__generated__';
import Quote, { QuoteOwnProps } from '../Quote/Quote';

export const QUOTE_SINGLE_FRAGMENT = gql(`
  fragment quoteSingle on quoteSingle_Entry {
    heading
    label
  }  
`);

const QuoteSingle = withFragment(QUOTE_SINGLE_FRAGMENT, (data, props: QuoteOwnProps) => {
  return <Quote {...data} {...props} />;
});

export default QuoteSingle;
