import { gql } from '__generated__';

export const ARTICLE_ENTRY_FRAGMENT = gql(`
  fragment articleEntry on article_Entry {
    ...entryBase
    entryTitle
    entrySummary
    entryImage {
      ...ImageAsset
    }
    blocks {
      ...blocks
    }
    authorMultiple {
      ...profileCard
    }
    serviceMultiple {
      ...serviceCard
    }
    profileMultiple {
      ...profileCard
    }
    articleMultiple {
      id
      ...entryCards
    }
    contentTypeCategory {
      ...contentTypeCategory
    }
    profileLimit: number
    disableQuicklinks
  }
`);

export const ARTICLE_INDEX_ENTRY_FRAGMENT = gql(`
  fragment articleIndexEntry on articleIndex_Entry {
    ...entryBase
    entryTitle
    entrySummary
    entryImage {
      ...ImageAsset
    }
    featuredArticle: articleSingle {
      ...articleCard
    }
  }
`);

export const HOME_ENTRY_FRAGMENT = gql(`
  fragment homeEntry on home_Entry {
    ...entryBase
    entryTitle
    entrySummary
    entryImage {
      ...ImageAsset
    }
    blocks {
      ...blocks
    }
    theme {
      ...themeEntry
    }
  }
`);

export const INSIGHT_ENTRY_FRAGMENT = gql(`
  fragment insightEntry on insight_Entry {
    ...entryBase
    entryTitle
    entrySummary
    entryImage {
      ...ImageAsset
    }
    blocks {
      ...blocks
    }
    authorMultiple {
      ...profileCard
    }
    serviceMultiple {
      ...serviceCard
    }
    profileMultiple {
      ...profileCard
    }
    insightMultiple {
      id
      ...entryCards
    }
    profileLimit: number
    disableQuicklinks
  }
`);

export const INSIGHT_INDEX_ENTRY_FRAGMENT = gql(`
  fragment insightIndexEntry on insightIndex_Entry {
    ...entryBase
    entryTitle
    entrySummary
    featuredInsight: insightSingle {
      ...insightCard
    }
    guideMultiple {
      ...articleCard
    }
  }
`);

export const PAGE_OVERVIEW_ENTRY_FRAGMENT = gql(`
  fragment pageOverviewEntry on overview_Entry {
    ...entryBase
    entryTitle
    entrySummary
    entryImage {
      ...ImageAsset
    }
    blocks {
      ...blocks
    }
    colorScheme {
      label
    }
  }
`);

export const PROFILE_ENTRY_FRAGMENT = gql(`
  fragment profileEntry on profile_Entry {
    ...entryBase
    entryTitle
    entrySummary
    entryImage {
      ...ImageAsset
    }
    phoneNumber {
      number
    }
    officeNumber {
      number
    }
    displayProfile
    email
    externalUrl
    locationCategory {
      ...locationCategory
    }
    profileRoleCategory {
      ...profileRoleCategory
    }
    serviceMultiple {
      ...serviceCard
    }
    intro
    content: htmlContentDefault
    heading
    twoColumnContent {
      ...genericContent
      ...heading
    }
    quoteMultiple {
      ...quoteSingle
    }
    colorScheme {
        label
    }
    insightMultiple {
      id
      ...entryCards
    }
    vCard {
      ...vCardAsset
    }
  }
`);

export const PROFILE_INDEX_ENTRY_FRAGMENT = gql(`
  fragment profileIndexEntry on profileIndex_Entry {
    ...entryBase
    entryTitle
    entrySummary
    entryImage {
      ...ImageAsset
    }
  }
`);

export const SEARCH_ENTRY_FRAGMENT = gql(`
  fragment searchEntry on search_Entry {
    ...entryBase
    entryTitle
    entrySummary
    entryImage {
      ...ImageAsset
    }
  }
`);

export const SERVICE_ENTRY_FRAGMENT = gql(`
    fragment serviceEntry on service_Entry {
      ...entryBase
      entryTitle
      entrySummary
      entryImage {
        ...ImageAsset
      }
      blocks {
        ...blocks
      }
      quoteMultiple {
        ...quoteSingle
      }
      colorScheme {
        label
      }
      serviceMultiple {
        ...serviceCard
      }
      profileMultiple {
        ...profileCard
      }
      articleMultiple {
        id
        ...entryCards
      }
      insightMultiple {
        id
        ...entryCards
      }
      profileLimit: number
      breadcrumb: entrySingle {
        ...entryCards
      }
    }
`);

export const SERVICE_INDEX_ENTRY_FRAGMENT = gql(`
  fragment serviceIndexEntry on serviceIndex_Entry {
    ...entryBase
    entryTitle
    entrySummary
    entryImage {
      ...ImageAsset
    }
  }
`);

export const PAGE_STANDARD_ENTRY_FRAGMENT = gql(`
  fragment pageStandardEntry on standard_Entry {
    ...entryBase
    entryTitle
    entrySummary
    entryImage {
      ...ImageAsset
    }
    blocks {
      ...blocks
    }
  }
`);
