'use client';

import { parseLinkField } from '@/lib/parsers/linkField';
import { simplifyEntry } from '@/lib/utils/format';
import { excludeEmptyTypedElements, firstNonNullable } from '@liquorice/utils';
import { CustomCardBlockFragment } from '__generated__/graphql';
import Card from '../Card';
import { SERVICE_CARD_FRAGMENT } from './ServiceCard';

const HighlightCustomCard = (data: CustomCardBlockFragment) => {
  if (!data) return null;

  const { heading, content, imageSingle, serviceMultiple } = data;
  const services = excludeEmptyTypedElements(serviceMultiple);
  const categories = simplifyEntry(SERVICE_CARD_FRAGMENT, services);
  const linkField = parseLinkField(data.linkField);
  const linkFirst = firstNonNullable(linkField);

  const href = linkFirst?.href;

  return (
    <Card
      disableLinkWrap
      elevation={false}
      item={{
        title: heading,
        titleComponent: href ? 'a' : undefined,
        categories,
        description: content,
        image: imageSingle,
        href,
      }}>
      <Card.Body cx={{ rowGap: 'sm', mB: 'sm' }}>
        <Card.Title variant="h3" />
        <Card.Categories variant="outlined" color="gum" />
        <Card.Description />
      </Card.Body>
      {href && (
        <Card.Foot>
          <Card.Cta as="a" href={href} underline="none" color="gum" bold />
        </Card.Foot>
      )}
    </Card>
  );
};

export default HighlightCustomCard;
