import { EntryTypeId } from '@/lib/parsers/entries';
import { Sprinkles } from '@/theme/sprinkles.css';
import Box, { BoxProps } from '../ui/Box';
import Container, { ContainerProps } from '../ui/Container';
import Grid, { GridColProps, GridProps } from '../ui/Grid';
import PageSummary from './PageSummary';
import PageTitle from './PageTitle';

export type PageHeaderRootProps = BoxProps<
  'header',
  {
    variant?: EntryTypeId | 'service';
    maxWidth?: ContainerProps['maxWidth'];
    show?: {
      date?: boolean;
      author?: boolean;
    };
    afterGrid?: React.ReactNode;
    afterCol?: React.ReactNode;
    beforeCol?: React.ReactNode;
    afterContainer?: React.ReactNode;
    GridProps?: GridProps;
    GridColProps?: GridColProps;
    title?: string | null;
    summary?: string | null;
  }
>;

const PageHeaderRoot = ({
  children,
  variant = 'standard',
  maxWidth,
  afterGrid,
  afterCol,
  beforeCol,
  afterContainer,
  cx: customCx,
  GridProps: customGridProps,
  GridColProps,
  title,
  summary,
  ...props
}: PageHeaderRootProps) => {
  const cx: Sprinkles = {
    position: 'relative',
    ...customCx,
  };

  const { cx: customGridCx, ...restCustomGridProps } = customGridProps || {};

  const GridProps: GridProps = {
    cx: {
      rowGap: 'lg',
      pT: '3xl',
      pB: '6xl',
      ...customGridCx,
    },
    ...(variant === 'profileIndex' && {
      cx: {
        pB: 'md',
        pT: '3xl',
      },
    }),
    ...(variant === 'articleIndex' && {
      cx: {
        pY: '3xl',
      },
    }),
    ...(variant === 'home' && {
      cx: {
        pY: 'none',
      },
    }),
    ...restCustomGridProps,
  };

  const container = beforeCol || title || summary || children || afterGrid || afterCol;

  return (
    <Box as="header" {...props} cx={cx}>
      {container && (
        <Container maxWidth={maxWidth}>
          <Grid {...GridProps}>
            {beforeCol}
            {(title || summary) && (
              <Grid.Col {...GridColProps}>
                {title && <PageTitle>{title}</PageTitle>}
                {summary && <PageSummary useGridCol={false}>{summary}</PageSummary>}
              </Grid.Col>
            )}
            {children && <Grid.Col {...GridColProps}>{children}</Grid.Col>}
            {afterCol}
          </Grid>
          {afterGrid}
        </Container>
      )}
      {afterContainer}
    </Box>
  );
};

export default PageHeaderRoot;
