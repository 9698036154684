import { withFragment, withFragmentArray } from '@liquorice/gql-utils';
import { makeNonNullableArray, parseUri } from '@liquorice/utils';
import { FragmentType } from '__generated__';
import { SERVICE_CARD_FRAGMENT } from '../EntryCards/ServiceCard';
import Btn, { BtnProps } from '../ui/Btn';

type ServiceButtons<D extends React.ElementType = 'button'> = {
  data: FragmentType<typeof SERVICE_CARD_FRAGMENT>[] | FragmentType<typeof SERVICE_CARD_FRAGMENT>;
  BtnProps?: BtnProps<D>;
  isLink?: boolean;
};

const ServiceButtons = <D extends React.ElementType>({
  data,
  BtnProps,
  isLink,
}: ServiceButtons<D>) => {
  if (!data) return null;

  const Multiple = withFragmentArray(SERVICE_CARD_FRAGMENT, (data) => {
    const items = makeNonNullableArray(data);

    return items?.map((item) => {
      return (
        <Btn
          key={item.id}
          size="tiny"
          color="gum"
          variant="outlined"
          {...BtnProps}
          {...(isLink && { as: 'a', href: parseUri(item.uri) })}>
          {item?.entryTitle}
        </Btn>
      );
    });
  });

  const Single = withFragment(SERVICE_CARD_FRAGMENT, (data) => {
    return (
      <Btn
        size="tiny"
        color="gum"
        variant="outlined"
        {...BtnProps}
        {...(isLink && { as: 'a', href: parseUri(data?.uri) })}>
        {data?.entryTitle}
      </Btn>
    );
  });

  if (Array.isArray(data)) {
    return <Multiple data={data} />;
  } else {
    return <Single data={data} />;
  }
};

export default ServiceButtons;
