'use client';

import { withFragment } from '@liquorice/gql-utils';
import { makeNonNullableArray, parseUri } from '@liquorice/utils';
import { gql } from '__generated__';
import BasicCard from '../BasicCard';
import { CardBase } from '../Card/useCard';
import { OverrideCardProps } from './EntryCard';

export const SERVICE_CARD_FRAGMENT = gql(`
  fragment serviceCard on service_Entry {
    id
    uri
    slug
    url
    sectionHandle
    entryTitle
    entrySummary
    entryImage {
      ...ImageAsset
    }
  }
`);

const ServiceCard = withFragment(SERVICE_CARD_FRAGMENT, (data, props: OverrideCardProps) => {
  if (!data) return null;

  const { entryImage, entryTitle, entrySummary, uri } = data;
  const { heading, content } = props;
  const href = parseUri(uri);
  const maybeImage = makeNonNullableArray(props.image);

  const image = !!maybeImage.length ? maybeImage : entryImage;

  const item: CardBase = {
    title: heading ?? entryTitle,
    description: content ?? entrySummary,
    image,
    href,
  };

  return <BasicCard item={item} />;
});

export default ServiceCard;
