'use client';

import { getHeaderColor } from '@/lib/utils/getHeaderColor';
import { Color, Tint } from '@/theme/partials/palette.css';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import useResizeObserver from 'use-resize-observer';
import HideOnScroll from '../HideOnScroll';
import Box, { BoxProps } from '../ui/Box';
import * as styles from './NavBar.css';
import { useNavBarActions } from './useNavBar';

export type NavBarInnerProps<D extends React.ElementType = 'nav'> = BoxProps<
  D,
  {
    typeHandle?: string | null;
    OverrideTheme?: Color;
  }
>;

export default function NavBarInner({
  children,
  typeHandle,
  OverrideTheme,
  ...props
}: NavBarInnerProps) {
  const { theme, setHeight, fallbackHeight, height } = useNavBarActions();
  const themeColorSet = theme?.colorScheme?.label as Tint;
  const headerColorSet = getHeaderColor(typeHandle, OverrideTheme);
  const { ref, height: _initialHeight = fallbackHeight } = useResizeObserver<HTMLDivElement>({
    onResize: ({ height }) => {
      setHeight(height);
    },
  });

  const isHome = typeHandle === 'home';

  return (
    <HideOnScroll>
      <Box
        as="nav"
        colorSet={!isHome ? headerColorSet : undefined}
        tintSet={isHome ? themeColorSet : undefined}
        paper={!isHome}
        tint={isHome}
        ref={ref}
        className={styles.inner}
        style={assignInlineVars({
          [styles.navbarHeight]: `${height}px`,
        })}
        {...props}>
        <Box cx={{ pY: 'lg', pX: { xs: 'margin', lg: 'gutter' } }}>{children}</Box>
      </Box>
    </HideOnScroll>
  );
}
